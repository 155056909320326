<template>
  <div>
    <Header></Header>
    <div class="container">
      <el-row>
        <el-col :offset="4" :md="16">
          <el-tabs
            v-model="activeName"
            type="card"
            class="demo-tabs"
            @tab-click="handleClick"
          >
            <el-tab-pane label="个人信息" name="info">
              <el-form
                ref="infoFormRef"
                :model="infoForm"
                :rules="infoRules"
                label-width="120px"
              >
                <el-form-item label="姓名" prop="trueName">
                  <el-input
                    v-model="infoForm.trueName"
                    placeholder="请输入真实姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="userName">
                  <el-input
                    v-model="infoForm.userName"
                    placeholder="6-18个英文字母或数字"
                    :disabled="isToB"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                  <el-input
                    v-model="showTel"
                    placeholder="手机号"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input
                    v-model="showMail"
                    placeholder="邮箱"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item class="mobile-btn">
                  <el-button
                    class="largeBtn"
                    type="primary"
                    @click="setUserInfo()"
                    >确认修改</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="头像设置" name="portrait">
              <el-row>
                <el-col :span="6" :xs="24" class="mobilCenter"
                  ><el-avatar :size="120" :src="dialogImageUrl"
                /></el-col>
                <el-col
                  :span="12"
                  :xs="24"
                  class="mt30 ml20 port-text mobilCenter"
                >
                  <el-upload
                    ref="upload"
                    :action="uploadUrl"
                    :headers="{ Authorization: authorization }"
                    :auto-upload="true"
                    name="portrait"
                    accept="image/jpeg,image/jpg,image/png"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleAvatarSuccess"
                    :show-file-list="false"
                  >
                    <template #trigger>
                      <el-button type="primary">选择图片上传</el-button>
                    </template>
                    <template #tip>
                      <div class="el-upload__tip text-red">
                        请使用2M以内的jpg、jpeg、png图片
                      </div>
                    </template>
                  </el-upload>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane :label="passwordPaneText" name="password">
              <el-form
                ref="passwordFormRef"
                :model="passwordForm"
                :rules="passwordRules"
                label-width="120px"
              >
                <el-form-item
                  v-show="oldPasswordShow"
                  label="原密码"
                  prop="oldPassword"
                >
                  <el-input
                    v-model="passwordForm.oldPassword"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="请输入旧的密码"
                  />
                  <div class="eye-p">
                    <img
                      v-show="showPassword"
                      src="@/assets/image/eye.png"
                      @click="showPassword = false"
                    />
                    <img
                      v-show="!showPassword"
                      src="@/assets/image/closeEye.png"
                      @click="showPassword = true"
                    />
                  </div>
                </el-form-item>
                <el-form-item :label="newPasswordText" prop="newPassword">
                  <el-input
                    v-model="passwordForm.newPassword"
                    :type="showPassword2 ? 'text' : 'password'"
                    placeholder="8-32位，含大小写字母、数字或特殊符号的其中两项"
                  />
                  <div class="eye-p">
                    <img
                      v-show="showPassword2"
                      src="@/assets/image/eye.png"
                      @click="showPassword2 = false"
                    />
                    <img
                      v-show="!showPassword2"
                      src="@/assets/image/closeEye.png"
                      @click="showPassword2 = true"
                    />
                  </div>
                </el-form-item>
                <el-form-item label="确认密码" prop="rePassword">
                  <el-input
                    v-model="passwordForm.rePassword"
                    :type="showPassword3 ? 'text' : 'password'"
                    placeholder="需和上次输入一致"
                  />
                  <div class="eye-p">
                    <img
                      v-show="showPassword3"
                      src="@/assets/image/eye.png"
                      @click="showPassword3 = false"
                    />
                    <img
                      v-show="!showPassword3"
                      src="@/assets/image/closeEye.png"
                      @click="showPassword3 = true"
                    />
                  </div>
                </el-form-item>
                <el-form-item class="mobile-btn">
                  <el-button
                    class="largeBtn"
                    type="primary"
                    @click="savePassWord()"
                    >{{ passwordBtnText }}</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane :label="telPaneText" name="tel">
              <el-form
                ref="telFormRef"
                :model="telForm"
                :rules="telRules"
                label-width="120px"
              >
                <el-form-item :label="telText" prop="tel">
                  <el-input
                    v-model="telForm.tel"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="password">
                  <el-input
                    v-model="telForm.password"
                    :type="showPassword4 ? 'text' : 'password'"
                    placeholder="请输入密码"
                  />
                  <div class="eye-p">
                    <img
                      v-show="showPassword4"
                      src="@/assets/image/eye.png"
                      @click="showPassword4 = false"
                    />
                    <img
                      v-show="!showPassword4"
                      src="@/assets/image/closeEye.png"
                      @click="showPassword4 = true"
                    />
                  </div>
                </el-form-item>
                <el-form-item label="验证码" prop="sms">
                  <el-row :gutter="20">
                    <el-col :span="18" :xs="13">
                      <el-input
                        v-model="telForm.sms"
                        placeholder="验证码"
                        @blur="
                          verifyCode(telForm.tel, telForm.sms, 'updateTel')
                        "
                      />
                    </el-col>
                    <el-col v-show="sendBtnShow" :span="6">
                      <el-button
                        @click="
                          sendVerify(
                            telForm.tel,
                            'updateTel',
                            telForm.password
                          );
                          setTime();
                        "
                        >发送验证码</el-button
                      >
                    </el-col>
                    <el-col v-show="!sendBtnShow" :span="6">
                      <el-button>重新发送({{ counter }}s)</el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item class="mobile-btn">
                  <el-button
                    class="largeBtn"
                    type="primary"
                    @click="saveTel()"
                    >{{ telBtnText }}</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="隐私与协议" name="agreement">
              <div class="p1">
                <router-link target="_blank" to="/agreement">
                  《外研教学评用户协议》
                </router-link>
              </div>
              <div class="p1">
                <router-link target="_blank" to="/privacy">
                  《外研教学评隐私政策》
                </router-link>
              </div>
              <div class="p1">
                <router-link target="_blank" to="/privacyAbstract">
                  《外研教学评隐私政策摘要》
                </router-link>
              </div>
              <div class="p1">
                <router-link target="_blank" to="/childrenPrivacy">
                  《外研教学评儿童隐私保护声明》
                </router-link>
              </div>
              <div class="p1">
                <router-link target="_blank" to="/personalInfoList">
                  《个人信息收集清单》
                </router-link>
              </div>
              <div class="p1">
                <router-link target="_blank" to="/thirdParty">
                  《第三方信息共享清单》
                </router-link>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="其他设置"
              name="otherSet"
              style="text-align: center"
            >
              <el-button
                class="largeBtn"
                type="primary"
                @click="clickUnregister()"
                >注销账号</el-button
              >
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      v-model="confirmVisible"
      title="确认注销账号"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="tipText">
        <p>注销前请认真阅读以下内容。</p>
        <p>
          注销账号后，账号绑定手机、邮箱、姓名等个人信息将被释放，账号将无法登录。一旦注销，账号无法恢复。请谨慎考虑账号的注销。
        </p>
        <p>
          注销代表您同意放弃您在《悦教大讲堂》、《外研拼读》、《悦测评》、《iWay》中的相关权益或虚拟资产，请核实您是否存在未使用的阅读豆及未消费的考试次数。
        </p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="closeConfirmOpenUn">
            好的，我已阅读
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="unRegisterFormVisible"
      title="注销账号"
      :close-on-click-modal="false"
      :show-close="false"
      :destroy-on-close="true"
    >
      <el-form ref="unFormRef" :model="unRegisterForm" :rules="unRules">
        <el-form-item label="手机号：" prop="tel">
          <el-input :rows="2" v-model="unRegisterForm.tel" disabled />
        </el-form-item>
        <el-form-item label="验证码：" prop="code">
          <el-row :gutter="20">
            <el-col :span="18" :xs="12">
              <el-input :rows="2" v-model="unRegisterForm.code" />
            </el-col>
            <el-col v-show="sendBtnShow" :span="4">
              <el-button
                @click="
                  sendVerify(unRegisterForm.tel, 'unregister');
                  setTime();
                "
                >发送验证码</el-button
              >
            </el-col>
            <el-col v-show="!sendBtnShow" :span="6">
              <el-button>重新发送({{ counter }}s)</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeUnregister">取消</el-button>
          <el-button type="primary" @click="unRegister"> 注销 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import { onMounted, toRefs, reactive, getCurrentInstance } from "vue";
import {
  toGetUserInfo,
  toSetUserInfo,
  toSavePassword,
  toSaveTel,
  toSendVerify,
  toUnRegister,
} from "@/api/api.js";
import { localStore, verifyCode, _checkToken } from "@/utils/utils.js";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import {
  validateTruename,
  validateUsername,
  validatePassword,
  validateTel,
  validateNull,
  validatePassNull,
} from "@/utils/validate.js";

export default {
  components: { Header },
  setup() {
    const { proxy } = getCurrentInstance(); // 使用proxy代替ctx，因为ctx只在开发环境有效
    const router = useRouter();
    const state = reactive({
      activeName: "info",
      isToB: false,
      showTel: "",
      showMail: "",
      infoForm: {
        trueName: "",
        userName: "",
      },
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        rePassword: "",
      },
      telForm: {
        tel: "",
        password: "",
        sms: "",
      },
      unRegisterForm: {
        tel: "",
        code: "",
      },
      passwordPaneText: "修改密码",
      oldPasswordShow: true,
      newPasswordText: "新密码",
      passwordBtnText: "修改密码",
      telText: "新手机号",
      telBtnText: "修改手机号",
      telPaneText: "修改手机号",
      infoFormRef: null,
      passwordFormRef: null,
      telFormRef: null,
      hasPassword: true,
      sendBtnShow: true,
      counter: 60,
      uploadUrl:
        process.env.VUE_APP_BASE_PATH +
        "/users/" +
        localStore("userId") +
        "/portrait",
      dialogImageUrl: require("@/assets/image/portrait.png"),
      authorization: localStore("tokenType") + " " + localStore("token"),
      confirmVisible: false,
      unRegisterFormVisible: false,
      unFormRef: null,
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      showPassword4: false,
    });
    const unRules = {
      tel: [{ validator: validateTel, trigger: "blur" }],
      code: [{ validator: validateNull, trigger: "blur" }],
    };
    const validateRePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else {
        if (value !== state.passwordForm.newPassword) {
          callback(new Error("两次密码输入不一致！"));
        }
        callback();
      }
    };
    const infoRules = {
      //trueName: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
      trueName: [{ validator: validateTruename, trigger: "blur" }],
      userName: [{ validator: validateUsername, trigger: "blur" }],
    };
    const passwordRules = {
      newPassword: [{ validator: validatePassword, trigger: "blur" }],
      rePassword: [{ validator: validateRePassword, trigger: "blur" }],
    };
    const telRules = {
      tel: [{ validator: validateTel, trigger: "blur" }],
      password: [{ validator: validatePassNull, trigger: "blur" }],
      sms: [{ validator: validateNull, trigger: "blur" }],
    };
    onMounted(() => {
      _checkToken();
      getUserInfo();
    });
    const getUserInfo = () => {
      toGetUserInfo().then((response) => {
        var user = response["data"];
        state.infoForm.trueName = user["fullName"];
        state.infoForm.userName = user["userName"];
        //设置tob用户的用户名不可修改
        if (user["registerType"] !== 1) {
          state.isToB = true;
        }
        state.showTel = user["mobile"];
        state.showMail = user["email"];
        state.hasPassword = user["hasPassword"];
        if (user["portrait"]) {
          state.dialogImageUrl = user["portrait"];
          localStore("portrait", user["portrait"]);
        }
        if (!user["hasPassword"]) {
          //如果没有设置过密码
          setPasswordSetting();
        }
        if (!user["mobile"]) {
          //如果没有绑定过手机号
          setTelSetting();
        }
      });
    };
    const setPasswordSetting = () => {
      state.oldPasswordShow = false;
      state.passwordPaneText = "设置密码";
      state.newPasswordText = "密码";
      state.passwordBtnText = "保存密码";
    };
    const setTelSetting = () => {
      state.telPaneText = "绑定手机号";
      state.telText = "手机号";
      state.telBtnText = "确认绑定";
    };
    const setUserInfo = () => {
      state.infoFormRef.validate((valide) => {
        if (valide) {
          toSetUserInfo({
            fullName: state.infoForm.trueName,
            userName: state.infoForm.userName,
            userRole: localStore("userRole"),
            userId: localStore("userId"),
          })
            .then((response) => {
              if (response["status"] == 0) {
                ElMessage({
                  message: response["msg"],
                  type: "success",
                });
                getUserInfo();
              } else if (response["status"] == -1) {
                //用户名已存在的情况添加提示
                ElMessage({
                  message: response["data"]["userName"],
                  type: "warning",
                });
              } else {
                ElMessage({
                  message: response["msg"],
                  type: "warning",
                });
              }
            })
            .catch((response) => {
              ElMessage({
                message: response["msg"],
                type: "warning",
              });
            });
        }
      });
    };
    const savePassWord = () => {
      state.passwordFormRef.validate((valide) => {
        if (valide) {
          var datas = {};
          if (state.hasPassword) {
            datas = {
              newPassword: proxy.$encryption(state.passwordForm.newPassword),
              oldPassword: proxy.$encryption(state.passwordForm.oldPassword),
            };
          } else {
            datas = {
              newPassword: proxy.$encryption(state.passwordForm.newPassword),
            };
          }
          toSavePassword(datas)
            .then((response) => {
              if (response["status"] == 0) {
                ElMessage({
                  message: "密码修改成功！",
                  type: "success",
                });
                state.passwordForm.oldPassword = "";
                state.passwordForm.newPassword = "";
                state.passwordForm.rePassword = "";
                localStore("isExpiredPass", "false");
                proxy.$passwordTip = false;
                state.pTipShow = proxy.$passwordTip;
                setTimeout(() => window.location.reload(), 2000);
                state.activeName = "password";
              } else {
                ElMessage({
                  message: response["msg"],
                  type: "warning",
                });
              }
            })
            .catch((response) => {
              ElMessage({
                message: response["msg"],
                type: "error",
              });
            });
        }
      });
    };
    const saveTel = () => {
      state.telFormRef.validate((valide) => {
        if (valide) {
          var datas = {
            mobile: state.telForm.tel,
            code: state.telForm.sms,
            password: proxy.$encryption(state.telForm.password),
          };
          toSaveTel(datas)
            .then((response) => {
              if (response["status"] == 0) {
                ElMessage({
                  message: response["msg"],
                  type: "success",
                });
                localStore("loginId", state.telForm.tel);
                localStore("mobile", state.telForm.tel);
                localStore("hasMobile", true);
                state.telForm.tel = "";
                state.telForm.password = "";
                state.telForm.sms = "";
                state.counter = 0;
                state.telBtnText = "修改手机号";
                getUserInfo();
              } else {
                ElMessage({
                  message: response["msg"],
                  type: "warning",
                });
              }
            })
            .catch((response) => {
              ElMessage({
                message: response["msg"],
                type: "error",
              });
            });
        }
      });
    };
    const setTime = () => {
      var timer;
      if (state.sendBtnShow) {
        state.sendBtnShow = false;
        timer = setInterval(() => {
          if (state.counter > 0 && state.counter <= 60) {
            state.counter--;
          } else {
            state.sendBtnShow = true;
            state.counter = 60;
            clearInterval(timer);
          }
        }, 1000);
      }
    };
    const beforeAvatarUpload = (rawFile) => {
      if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("图片大小不能超过2MB!");
        return false;
      }
      return true;
    };
    const handleAvatarSuccess = (response, uploadFile) => {
      state.dialogImageUrl = uploadFile.url;
      getUserInfo();
    };
    //发送验证码
    const sendVerify = (tel, type, password) => {
      if (/^1[0-9]{10}$/.test(tel)) {
        //校验手机号是否正确
        toSendVerify({
          mobile: tel,
          type: type,
          userId: localStore("userId"),
          password: proxy.$encryption(password),
        })
          .then((response) => {
            if (response["status"] == 0) {
              ElMessage({
                message: response["msg"],
                type: "success",
              });
            } else {
              ElMessage({
                message: response["msg"],
                type: "warning",
              });
              state.counter = 0;
            }
          })
          .catch(() => {
            state.counter = 0;
          });
      } else {
        ElMessage({
          message: "请输入正确的手机号！",
          type: "warning",
        });
        state.counter = 0;
      }
    };
    const clickUnregister = () => {
      if (state.isToB == true) {
        if (localStore("userRole") == 4) {
          ElMessage({
            message: "请联系老师或学校管理员帮您注销账号",
            type: "warning",
          });
        } else if (localStore("userRole") == 5 || localStore("userRole") == 6) {
          ElMessage({
            message: "请联系系统管理员帮您注销账号",
            type: "warning",
          });
        } else if (localStore("userRole") == 1) {
          ElMessage({
            message: "请联系其他系统管理员帮您注销账号",
            type: "warning",
          });
        }
      } else {
        state.confirmVisible = true;
      }
    };
    const closeConfirmOpenUn = () => {
      state.confirmVisible = false;
      if (state.showTel) {
        state.unRegisterForm.tel = state.showTel;
        state.unRegisterFormVisible = true;
        state.counter = 60;
      } else {
        ElMessage({
          message: "您没有绑定手机号，无法自行注销账号，请联系管理员帮您注销",
          type: "warning",
        });
      }
    };
    const closeUnregister = () => {
      state.unRegisterFormVisible = false;
      state.unRegisterForm.tel = "";
      state.unRegisterForm.code = "";
      state.counter = 0;
    };
    const unRegister = () => {
      state.unFormRef.validate((valide) => {
        if (valide) {
          toUnRegister({
            mobile: state.unRegisterForm.tel,
            code: state.unRegisterForm.code,
          }).then((response) => {
            if (response["status"] == 0) {
              ElMessage({
                message: response["msg"],
                type: "success",
              });
              localStorage.clear();
              router.push("/login");
            } else {
              ElMessage({
                message: response["msg"],
                type: "warning",
              });
            }
          });
        }
      });
    };
    return {
      ...toRefs(state),
      setUserInfo,
      savePassWord,
      saveTel,
      infoRules,
      passwordRules,
      telRules,
      verifyCode,
      sendVerify,
      setTime,
      beforeAvatarUpload,
      handleAvatarSuccess,
      clickUnregister,
      closeUnregister,
      closeConfirmOpenUn,
      unRules,
      unRegister,
    };
  },
};
</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.el-tab-pane {
  padding: 10px;
}
.p1 {
  padding: 1rem;
}
.tipText {
  line-height: 28px;
  font-size: 1rem;
  text-decoration: underline;
}
.tipText p {
  text-indent: 2rem;
}
</style>
